import React from "react";
import frame1 from "../Images/Frame 156.svg";
import frame2 from "../Images/Frame 156 (1).svg";
import frame3 from "../Images/Frame 156 (2).svg";
import frame4 from "../Images/Frame 156 (3).svg";
import frame5 from "../Images/Frame 156 (4).svg";
import frame6 from "../Images/Frame 156 (5).svg";
import avatar1 from "../Images/Avatar Image.svg";
import avatar2 from "../Images/Avatar Image (1).svg";
import avatar3 from "../Images/Avatar Image (2).svg";
import avatar4 from "../Images/Avatar Image (3).svg";
import avatar5 from "../Images/Avatar Image (4).svg";


const PopularBox = ({ frame, title, avatars, count }) => {
	return (
		<div className="box">
			<img src={frame} alt="" className="image"/>
			<h3>{title}</h3>
			<div className="avatar-box">
				{avatars.map((avatar, index) => (
					<img key={index} src={avatar} alt="" />
				))}
				<span className="circle">{count}+</span>
			</div>
		</div>
	);
};

export const Popular = () => {
	return (
		<div className="popular">
			<h1>
				Popular <span>Now</span>
			</h1>
			<div className="container">
				<PopularBox
					frame={frame1}
					title="Health Community"
					avatars={[avatar1, avatar2, avatar3, avatar4, avatar5]}
					count={500}
				/>
				<PopularBox
					frame={frame2}
					title="Music Community"
					avatars={[avatar1, avatar2, avatar3, avatar4, avatar5]}
					count={350}
				/>
				<PopularBox
					frame={frame3}
					title="Tech Community"
					avatars={[avatar1, avatar2, avatar3, avatar4, avatar5]}
					count={250}
				/>
				<PopularBox
					frame={frame4}
					title="Marketplace Community"
					avatars={[avatar1, avatar2, avatar3, avatar4, avatar5]}
					count={250}
				/>
				<PopularBox
					frame={frame5}
					title="Relationship Community"
					avatars={[avatar1, avatar2, avatar3, avatar4, avatar5]}
					count={250}
				/>
				<PopularBox
					frame={frame6}
					title="Sport Community"
					avatars={[avatar1, avatar2, avatar3, avatar4, avatar5]}
					count={250}
				/>
			</div>
			<center>
				<button>Explore All Communities</button>
			</center>
		</div>
	);
};
