import React from "react";
import logo from "../Images/image 2.svg";
import message from '../Images/Frame 206.svg'
import phone from "../Images/Frame 208.svg";
import location from "../Images/Frame 211.svg";
import facebook from "../Images/Group 9.svg";
import linkedin from "../Images/Group 9 Copy.svg";
import twitter from "../Images/Group 9 Copy 2.svg";
import instagram from "../Images/Group 9 Copy 3.svg";

function ListItem({ path, label }) {
	return (
		<li>
			<a href={path}>{label}</a>
		</li>
	);
}

function Segment({ items }) {
	return (
		<div className="second-segment">
			{items.map((segment, index) => (
				<div className="child" key={index}>
					<div className="toppings">{segment.toppings}</div>
					<ul>
						{segment.items.map((listItem, idx) => (
							<ListItem key={idx} path={listItem.path} label={listItem.label} />
						))}
					</ul>
				</div>
			))}
		</div>
	);
}

export const Footer = () => {
	const data = [
		{
			toppings: "Company",
			items: [
				{ path: "/path1", label: "Cras convallis" },
				{ path: "/path2", label: "Cras convallis" },
				{ path: "/path3", label: "Cras convallis" },
				{ path: "/path4", label: "Cras convallis" },
			],
		},
		{
			toppings: "Services",
			items: [
				{ path: "/path5", label: "Cras convallis" },
				{ path: "/path6", label: "Cras convallis" },
				{ path: "/path7", label: "Cras convallis" },
				{ path: "/path8", label: "Cras convallis" },
			],
		},
		{
			toppings: "Legal",
			items: [
				{ path: "/privacy-policy", label: "Privacy Policy" },
				{ path: "/terms-and-conditions", label: "Terms & Conditions" },
				{ path: "/return-policy", label: "Return Policy" },
				{ path: "/path9", label: "Cras convallis" },
			],
		},
	];

	return (
		<footer>
			<div className="upper">
				<div className="first-segment">
					<div className="head">
						<img src={logo} alt="Company Logo" className="company-logo" />
						<span className="company-name">Koach Community</span>
					</div>
					<p>
						Your one-stop destination for connecting with communities worldwide.
						From politics to pop culture, dive into engaging discussions and
						forge new connections
					</p>
				</div>
				<Segment items={data} />
				<div className="third-segment">
					<div className="toppings">Contact Us</div>
					<div className="item-list">
						<div className="item">
							<img src={phone} alt="" />
							<p>Info.koachinternational@gmail.com</p>
						</div>
						<div className="item">
							<img src={message} alt="" />
							<p>+2348033711847, +1(216) 526-6259</p>
						</div>
						<div className="item">
							<img src={location} alt="" />
							<p>
								I12B, Adewumi Layout, Charity Ogbagba, Apata, Ibadan, Oyo state,
								Nigeria.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="lower">
				<p>© Koach Community 2024, All rights reserved.</p>
				<div className="socials">
					<a href="/path">
                        <img src={facebook} alt="" />
                    </a>
					<a href="/path">
                        <img src={linkedin} alt="" />
                    </a>
					<a href="/path">
                        <img src={twitter} alt="" />
                    </a>
					<a href="/path">
                        <img src={instagram} alt="" />
                    </a>
				</div>
			</div>
		</footer>
	);
};
