import React from 'react'
import message from '../Images/Icon.svg'

export const Trends = () => {
  return (
		<div className="trends">
			<img src={message} alt="message" />
			<h2>
				Stay updated with the Latest <span>Community</span> Trends{" "}
			</h2>
			<p>
				Subscribe to our newsletter and stay in the loop with the latest
				industry news, upcoming events, and exclusive opportunities, Sign up now
				and be part of the conversation!
			</p>
			<form action="" className="subscribe-form ">
				<input type="text" className="subscribe-input" placeholder='Enter your Email' />
				<input type="submit" value="Subscribe" className="subscribe-button" />
			</form>
		</div>
	);
}
