import React, { useState, useEffect } from "react";
import apple from "../Images/Apple.svg";
import playstore from "../Images/Google.svg";
import chat from "../Images/chat.svg";
import veiw from "../Images/view.svg";
import wave from "../Images/Wave.svg";

export const Open = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [titles, setTitles] = useState([
		"Uniting like-minded individuals across diverse communities at your fingertips",
		"find your squad, share your story",
		"Explore your interests, engage your minds",
	]);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex === 2 ? 1 : prevIndex + 1));
		}, 3000); // Change title every 3 seconds

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="open">
			<div className="left">
				<h1>{titles[currentIndex]}</h1>
				<p>
					With Koach you have secured your passport to a world of ideas and
					healthy interactions.
				</p>
				<h4>Download the Koach App on your smartphone</h4>
				<div className="download-link">
					<a href="/playstore">
						<img src={apple} alt="" />
					</a>
					<a href="/apple">
						<img src={playstore} alt="" />
					</a>
				</div>
			</div>
			<div className="right">
				<img src={currentIndex === 0 ? chat : veiw} alt="" />
			</div>
			<img src={wave} alt="" className="wave" />
		</div>
	);
};
