import React from "react";
import phone from "../Images/phone.svg";
import download from "../Images/ios-cloud-download-outline.svg";
import member from "../Images/ios-people-outline.svg";
import paw from "../Images/ios-paw-outline.svg";

export const About = () => {
	return (
		<div className="About">
			<img src={phone} alt="phone"  className="phone"/>
			<div className="about-text">
				<h3>
					About <span>Koach</span>
				</h3>
				<p>
					We are a digital hub for global connection and community engagement,
					Join millions of like-minded individuals from around the world as we
					come together to share, learn, and inspire.With Koach, you can explore
					diverse range of communities, from politics to sports, health to
					education , and everything in between.
				</p>
				<button className="btn-link">
					Read more
				</button>
				<div className="about-sub">
					<div className="contain">
						<div className="txt">+200M</div>
						<div className="txt-cont">
							<img src={download} alt="" />
							<span>Download</span>
						</div>
					</div>
					<div className="contain">
						<div className="txt">+480M</div>
						<div className="txt-cont">
							<img src={member} alt="" />
							<span>Members</span>
						</div>
					</div>
					<div className="contain">
						<div className="txt">+18k</div>
						<div className="txt-cont">
							<img src={paw} alt="" />
							<span>Communties</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
