import './App.css';
import { Header } from './Components/Header';
import { Open } from './Components/Open';
import { About } from './Components/About';
import { Community } from './Components/Community';
import { Stories } from './Components/Stories';
import { Popular } from './Components/Popular';
import { Download } from './Components/Download';
import { Trends } from './Components/Trends';
import { Footer } from './Components/Footer';


function App() {
  return (
		<div className="App">
			<div className="bg">
				<Header />
				<Open />
			</div>
			<About />
			<Community />
			<Stories />
			<Popular />
			<Download />
			<Trends />
			<Footer />
		</div>
	);
}

export default App;
