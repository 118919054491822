import React from 'react'
import check from '../Images/Group.svg'
import group from '../Images/group photo.svg'

function FeatureList({ title, description }) {
	return (
		<div className="list">
			<img src={check} alt="" />
			<div className="txt">
				<h4>{title}</h4>
				<p>{description}</p>
			</div>
		</div>
	);
}


export const Community = () => {
  return (
		<div className="community">
			<h2>
				Why Join Koach <span>Community?</span>
			</h2>
			<p>
				You are not just a member, you are a valued part of a vibrant, diverse
				network
			</p>
			<div className="reasons">
				<div className="checklist">
					<FeatureList
						title="Discover Your Tribe"
						description="Connect with over 200 like-minded individuals, share your passions, and find your perfect place to belong."
					/>
					<FeatureList
						title="Personalised Experience Tailored Just for You"
						description="Personalize your experience by customizing your profile, following your favorite communities, and receiving recommendations tailored to your interests and interactions."
					/>
					<FeatureList
						title="Enhanced Social Interaction"
						description="Share images and videos to support your posts, update your status for followers, and chat directly with anyone who follows you."
					/>
					<FeatureList
						title="Spill and Report with Ease"
						description="Our Gossip community feature allows users to share rumors, stories, and news tidbits, all while providing a reporting system to address any concerns."
					/>
				</div>
				<img src={group} alt="" className='group' />
			</div>
		</div>
	);
}
