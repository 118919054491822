import React, { useState, useRef } from "react";
import avatar1 from "../Images/Avatar Image.svg";
import avatar2 from "../Images/Avatar Image (1).svg";
import avatar3 from "../Images/Avatar Image (2).svg";
import avatar4 from "../Images/Avatar Image (3).svg";
import avatar5 from "../Images/Avatar Image (4).svg";
import avatar6 from "../Images/Avatar Image (6).svg";
import avatar7 from "../Images/Avatar Image (5).svg";

const avatars = [avatar1, avatar6, avatar2, avatar7, avatar3, avatar4, avatar5];

// Assume these are the corresponding stories for each avatar
const stories = [
	{
		name: "John Smith",
		location: "San Francisco, USA",
		community: "Tech Community",
		story:
			"“Discovering the power of React Native revolutionized my approach to mobile app development. Now, I can build cross-platform apps with ease.”",
	},
	{
		name: "Maria Rodriguez",
		location: "Mexico City, Mexico",
		community: "Health Community",
		story:
			"“Embarking on a fitness journey changed my life. With the support of the health community, I've achieved my fitness goals and regained my confidence.”",
	},
	{
		name: "David Lee",
		location: "Seoul, South Korea",
		community: "Marketplace Community",
		story:
			"“Exploring the world of e-commerce has opened up new opportunities for my business. The marketplace community has been instrumental in helping me navigate the online marketplace landscape.”",
	},
	{
		name: "Serah Kim",
		location: "Lagos, Nigeria",
		community: "Music Community",
		story:
			"“Falling in love with Amapiano was unexpected, all thanks to Koach, Seeing people talk about it in the community sparkled my curiosity”",
	},
	{
		name: "Emily Johnson",
		location: "Sydney, Australia",
		community: "Sport Community",
		story:
			"“Joining the sport community has been a game-changer for me. From amateur athlete to competitive runner, the support and encouragement from fellow members have fueled my passion for sports.”",
	},
	{
		name: "Michael Brown",
		location: "London, UK",
		community: "Relationship Community",
		story:
			"“Navigating the complexities of relationships can be challenging, but the relationship community has provided invaluable advice and support along the way. Thanks to their guidance, I've built stronger connections and deeper relationships.”",
	},
	{
		name: "Mohammed Ahmed",
		location: "Cairo, Egypt",
		community: "Education Community",
		story:
			"“Diving into the world of online learning has opened doors to new knowledge and skills. With the guidance of the education community, I've expanded my horizons and embraced lifelong learning.”",
	},
];

const Avatar = ({ src, onClick, active }) => {
	return (
		<div
			className={`avatar ${active ? "active" : ""}`}
			onClick={onClick}
			style={{ cursor: "pointer" }}
		>
			<img src={src} alt="Avatar" />
		</div>
	);
};

export const Stories = () => {
	const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(0);
	const storyContainerRef = useRef(null);

	const handleAvatarClick = (index) => {
		setSelectedAvatarIndex(index);
		const avatarElement = document.getElementById(`avatar-${index}`);
		if (avatarElement) {
			avatarElement.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	return (
		<div className="stories">
			<h1>Stories</h1>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			<div className="avatar-section">
				<div className="overflow">
					<div className="avatar-scroll-container">
						{avatars.map((avatar, index) => (
							<Avatar
								key={index}
								src={avatar}
								onClick={() => handleAvatarClick(index)}
								active={index === selectedAvatarIndex}
							/>
						))}
					</div>
				</div>
				<div className="story-container" ref={storyContainerRef}>
					<p className="name">{stories[selectedAvatarIndex].name}</p>
					<p className="location">
						{" "}
						{stories[selectedAvatarIndex].location}-{" "}
						<span>{stories[selectedAvatarIndex].community}</span>
					</p>
					<p className="story">{stories[selectedAvatarIndex].story}</p>
				</div>
			</div>
		</div>
	);
};
