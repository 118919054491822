import React, { useState } from "react";
import logo from "../Images/image 2.svg"
import menu from "../Images/hamburger menu.svg"
import close from "../Images/hamburger menu (1).svg"
import dropdown from '../Images/Chevron Down.svg'
import vector from '../Images/Vector.svg'

export const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

		const toggleMobileMenu = () => {
			setIsMobileMenuOpen(!isMobileMenuOpen);
		};
	return (
		<div className="header">
			<div className="header-left">
				{/* Logo and company name */}
				<img src={logo} alt="Company Logo" className="company-logo" />
				<span className="company-name">Koach Community</span>
			</div>
			<div className="header-middle">
				{/* Navigation */}
				<nav className="nav">
					<ul>
						<li className="active">
							<a href="#Features">Features</a>
						</li>
						<li>
							<a href="#Blog">Blog</a>
						</li>
						<li>
							<a href="#contact">Contact Us</a>
						</li>
						<li className="list-dropdown">
							<a href="#product" className="drop-nav">
								<span>Our Product</span>
								<img src={dropdown} alt="" />
							</a>
							<div className="dropdown">
								<div className="dropdown-item">
									<a href="#item1">Item 1</a>
								</div>
								<div className="dropdown-item">
									<a href="#item2">Item 2</a>
								</div>
								<div className="dropdown-item">
									<a href="#item3">Item 3</a>
								</div>
							</div>
						</li>
					</ul>
				</nav>
			</div>
			<div className="header-right">
				{/* Join community button */}
				<button className="join-button">Join Community</button>
			</div>
			{/* Mobile menu */}
			<div className={`mobile-menu ${isMobileMenuOpen ? "open" : ""}`}>
				<img
					src={close}
					className="close-btn"
					alt="hamburger close"
					onClick={toggleMobileMenu}
				/>
				<nav className="mobile-nav">
					<ul>
						<li className="active">
							<a href="#Features">Features</a>
						</li>
						<li>
							<a href="#Blog">Blog </a>
						</li>
						<li>
							<a href="#contact">Contact Us</a>
						</li>
						<li className="list-dropdown">
							<a href="#product" className="drop-nav">
								<span>Our Product</span>
								<img src={vector} alt="" />
							</a>
							<div className="dropdown">
								<div className="dropdown-item">
									<a href="#item1">Item 1</a>
								</div>
								<div className="dropdown-item">
									<a href="#item2">Item 2</a>
								</div>
								<div className="dropdown-item">
									<a href="#item3">Item 3</a>
								</div>
							</div>
						</li>
					</ul>
				</nav>
			</div>
			{/* Mobile menu button */}
			<div className="mobile-menu-button" onClick={toggleMobileMenu}>
				<img src={menu} alt="hamburger menu" className="menu-icon" />
			</div>
		</div>
	);
};
