import React from 'react'
import apple from '../Images/Apple.svg'
import playstore from '../Images/Google.svg'

export const Download = () => {
  return (
		<div className="download">
			<h2>Ready to get started? Download Now</h2>
            <div className="download-link">
                <a href="/playstore">
                    <img src={apple} alt="" />
                    </a>
                    <a href="/apple">
                        <img src={playstore} alt="" />
                    </a>
            </div>
		</div>
	);
}
